@import 'assets/css/index';

.auth-page {
  display: flex;
  // height: 100vh;
  width: 100%;
  height: 100vh;
  // background: #FFFFFF;
  flex-direction: row;
  // margin: 65px auto 0;

  @media (max-width: 767px) {
    margin: 0 auto 0;
    padding: 0 17px;
    flex-direction: column;
  }
  .auth-leftpane {
    width: 100%;
    height: 100%;
    padding: 30px 30px;
    // left: 0px;
    // top: 20px;
    background: #f8fafb;
    // position: relative;
    /* border-right: 1px solid #ccc; */
    // box-shadow: 0px 0px 16px 0px #a9a9a9;
    // margin: 20px;
    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      // .auth-body-img {
      //   display: none;
      // }
    }

    .auth-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      height: calc(100% - 40px);
      .background-img-leftpane {
        width: 718px;
        height: 476px;
        @media (max-width: 991px) {
          display: none;
        }
      }
      // .auth-body-img{
      //   width: 200px;

      // }
    }
  }
  .auth-header-logo {
    // position: absolute;
    width: 175.56px;

    // left: 8%;
    // top: 8%;
    // transform: translate(-50%, -50%);
  }

  .auth-header-logo.nevada {
    background: #205a9c;
    border-radius: 10px;
    padding: 10px 0px;
  }

  @media (max-width: 767px) {
    .auth-header-logo {
      margin-bottom: 0;
    }
  }

  .auth-container {
    margin: auto;
    padding: 30px 50px;
    background: #fff;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
    height: 100%;
    margin: auto;
    padding: 30px 80px;
    background: #fff;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
    height: 100%;
    background: #ffffff;
    flex-direction: column;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-shrink: 0;
    width: 626px;
    background: #ffffff;
    &:after {
      // content: "";
      // position: absolute;
      // top: 20%;
      // right: -180px;
      // z-index: -1;
      // width: 330px;
      // height: 330px;
      // background-color: #eadbf0;
      // border-radius: 50%;
    }

    &:before {
      // content: "";
      // position: absolute;
      // bottom: -80px;
      // left: -80px;
      // z-index: -1;
      // width: 200px;
      // height: 200px;
      // background-color: #eadbf0;
      // border-radius: 50%;
    }
    nav {
      margin: 0;
      width: calc(100%);
      border-bottom: 1px solid $fun-blue-1;

      .nav-link {
        border-radius: 3px 3px 0px 0px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $white;
        background: $fun-blue-1;
        font-weight: 400;
        padding: 5px 30px;
        margin-right: 10px;

        color: #fff;
        border-color: $fun-blue-1 !important;
      }

      .nav-link.active {
        background: $white;
        color: $fun-blue-1;
        border-bottom: none;
      }
    }

    .tab-content {
      nav {
        margin: 0;
        width: 100%;
        margin-top: 25px;
        border-bottom: none !important ;
      }
      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #515151;
        margin-top: 30px;
        margin-bottom: 0px;
      }
      .nav-link {
        border-radius: 3px 3px 0px 0px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $white;
        background: transparent;
        font-weight: 400;
        padding: 5px 30px;
        margin-right: 10px;
        padding-left: 0;
        color: $fun-blue-1;
        border: none !important;
      }

      .nav-link.active {
        background: transparent;
        color: $fun-blue-1;
        font-weight: bold;
        border-bottom: 3px solid$fun-blue-1 !important;
      }
      .form-group {
        .email-error {
          margin-left: 6px;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ff403b;
        }
      }
    }
    .google-button {
      width: 100%;
      height: 32px;
      left: 958px;
      top: 370.78px;
      margin-bottom: 0;
      color: #515151;
      line-height: 20px;
      font-size: 14px;
      border: 1px solid #959595 !important;
      box-shadow: 0px 2px 6px 1px rgba(81, 81, 81, 0.16);
      display: flex;
      justify-content: center;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 0px 20px;
      img {
        margin: 3px;
      }
      div {
        margin: 6px;
      }
    }
    .email-button {
      min-width: 410.92px;
      height: 40px;
      color: #000000;
      left: 958px;
      top: 429.84px;
      border: 1px solid #888888 !important;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 0px 20px;
      margin-bottom: 56.85px;
    }
    .auth-title {
      letter-spacing: 0;
      color: #084892;
      left: 66.53%;
      right: 5%;
      top: 25.88%;
      bottom: 69.7%;
      font-style: normal;
      font-family: 'Rubic';
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 0px;
    }
    .termsandcondition {
      margin-top: 26.45px;
      width: 411.27px;
      height: 32.73px;
      left: 958.91px;
      top: 665.45px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #515151;
      a {
        color: #084892;
      }
    }
    @media (max-width: 767px) {
      padding: 25px;
      // margin: 15px;
      width: 100%;

      .termsandcondition {
        width: 100%;
        margin-top: 36.45px;
        font-size: 12.8px;
        line-height: 122.8%;
      }
      .auth-title {
        font-weight: 500;
        font-size: 29px;
        line-height: 26px;
        margin-bottom: 12.02px;
      }
    }

    .auth-subtitle {
      padding: 12px 0 18px;
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: $mine-shaft;
      text-transform: capitalize;
    }

    .auth-description {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #515151;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 20px;
      letter-spacing: 0;
      a {
        color: $curriki-main-blue;
        line-height: 22px;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $curriki-main-blue;
        }
      }
    }
    .auth-descriptionLogin {
      font-size: 16px;
      line-height: 22px;
    }
    .auth-Pdescrip {
      font-size: 16px;
      line-height: 24px;
      color: #515151;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      margin: 12px 0px 32px;
    }
    .auth-p2-descrip {
      font-size: 12.8px;
      line-height: 122.8%;
      color: #515151;

      a {
        color: $curriki-main-blue;
        line-height: 122.8%;
        cursor: pointer;
        &:hover {
          color: $curriki-main-blue;
        }
      }
    }

    @media (max-width: 767px) {
      .auth-subtitle {
        display: none;
      }

      .auth-description {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 8px;
      }
    }

    .auth-form {
      margin-top: 48px;
      width: 100%;
    }

    .content-section {
      padding: 0 16px;
    }

    .form-group {
      position: relative;
      margin-bottom: 16px;

      @media (max-width: 767px) {
        margin-bottom: 25px;
      }
      .bkbtn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 50px;
        a {
          color: $curriki-main-blue;
          text-decoration: none;
          cursor: pointer;
          padding-left: 10px;
        }
        .back-button {
          height: 32px;
          background: #084892 0 0 no-repeat padding-box;
          border-color: #084892;
          text-align: center;
          border: 0;
          width: 70px;
          font-weight: 400;
          font-size: 14px;
          line-height: 29px;
          border-radius: 50px;
          letter-spacing: 0.76px;
          color: #ffffff;
          opacity: 1;
          padding-bottom: 20px;
        }
      }
      .svg-inline--fa {
        position: absolute;
        top: 17px;
        left: 16px;
      }

      .input-wrapper {
        position: relative;
        margin: 0;
        flex: 1;

        &:last-child {
          margin-left: 1rem;
        }
      }

      .signUp-btn {
        width: 100%;
        background: #084892;
        border: 1px solid #084892;
        height: 32px;
        box-sizing: border-box;
        border-radius: 16px;
        font-family: 'Rubic';
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        margin-bottom: 15px;
        &:disabled {
          background: grey;
          border-color: grey;
        }
      }

      .vertical-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .line {
          width: 184px;
          height: 1px;
          background: #000000;
        }
        .line-or {
          font-weight: bold;
          font-size: 16px;
          line-height: 122.8%;
          color: #515151;
          margin: 0;
        }

        @media (max-width: 767px) {
          .line {
            width: 45%;
          }
        }
      }
      .using-curriki {
        display: flex;
        align-items: center;
        justify-content: start;
        .curriki-line {
          width: 40%;
        }
        .line-horizontal {
          border: 1px solid #dddddd;
          height: 0.5px;
          width: 60%;
          margin-left: 5px;
        }
      }
      .get-started-btn {
        width: 100%;
        height: 32px;
        background: #084892;
        border: 1px solid #084892;
        box-sizing: border-box;
        border-radius: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        opacity: 9 !important;
        &:disabled {
          background: grey;
          border-color: grey;
        }
      }
      .show-password {
        // display: flex;
        // justify-content: space-between;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
        svg {
          margin-right: 5px;
          margin-bottom: 4px;
        }
      }
      span {
        font-size: 14px;
        line-height: 20px;
        color: #515151;
        font-family: 'Rubic';
      }
    }

    .input-box {
      width: 100%;
      height: 36px;
      padding: 18px 18px 18px 8px;
      display: block;
      background-color: #fff !important;
      border: 1px solid #959595;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      color: $mine-shaft;
      margin-top: 4px;

      &:focus {
        outline: none;
        border: 1px solid #285aa5;
        box-sizing: border-box;
        filter: drop-shadow(0px 0px 2px #084892);
      }
    }
    .error-box {
      border: 1px solid red !important;
      border-color: red !important;
    }

    .organization-type {
      padding: 10px 18px 10px 8px;
    }

    .password-box {
      width: 100%;
      height: 36px;
      padding: 18px 18px 18px 8px;
      display: block;
      background-color: #fff;
      border: 1px solid #959595;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 14px;
      // color: #000;
      margin-top: 4px;

      &:focus {
        outline: none;
        border: 1px solid #285aa5;
        box-sizing: border-box;
        filter: drop-shadow(0px 0px 2px #084892);
      }
    }
    .remember-me {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      padding-bottom: 55px;
      input {
        width: 17px;
        height: 17px;
        margin-right: 9px;
        cursor: pointer;
      }

      a,
      label {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        font-family: $sans;
        letter-spacing: 0;
        color: $mine-shaft !important;
        opacity: 0.8;
        display: flex;
        align-items: center;
      }

      span {
        border: 1px solid #ccc;
        width: 18px;
        height: 18px;
        display: block;
        float: left;
        margin-top: 2px;
        margin-right: 12px;
        border-radius: 3px;
      }

      [type='checkbox']:checked + span {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: #374761;

        &::before {
          position: absolute;
          left: 2px;
          bottom: 3px;
          height: 8px;
          width: 3px;
          background-color: #fff;
          content: '';
          transform: translateX(5px) rotate(-45deg);
          transform-origin: left bottom;
        }

        &::after {
          position: absolute;
          left: 3px;
          bottom: 4px;
          height: 3px;
          width: 12px;
          background-color: #fff;
          content: '';
          transform: translateX(5px) rotate(-45deg);
          transform-origin: left bottom;
        }
      }
    }

    .forgot-password-box {
      // text-align: right;
      // padding-right: 10px;

      a {
        color: #285aa5 !important;
        font-family: $sans;
        font-weight: 400;
      }
    }
    .form-button {
      display: flex;
      justify-content: center;
      margin-bottom: 34px;
      .submit {
        width: 100%;
        height: 32px;
        background: #084892;
        border: 1px solid #084892;
        box-sizing: border-box;
        font-family: 'Rubic';
        border-radius: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.76px;
        box-shadow: 0px 2px 6px 1px rgba(8, 72, 146, 0.16);
        color: #ffffff;
        opacity: 1;

        &:disabled {
          background: grey;
          border-color: grey;
        }
      }
    }
    .login-separator-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 34px;

      .login-separator {
        border-bottom: 1px solid #084892;
        width: 80px;
      }
      .text-separator {
        padding: 0 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #084892;
      }
    }

    .vertical-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .line {
        width: 184px;
        height: 1px;
        background: #000000;
      }
      .line-or {
        font-weight: bold;
        font-size: 16px;
        line-height: 122.8%;
        color: #515151;
        margin: 0;
      }
    }

    .create-account-block {
      margin-top: 35px;

      p {
        font-size: 16px;
        color: #000;
        font-family: '$halvetica', sans-serif;
        font-weight: 400;

        a {
          font-weight: 500;
          color: #000;
        }
      }
    }
  }

  .bg1 {
    position: fixed;
    bottom: -83px;
    right: -20%;
    z-index: -1;
    display: none;
  }

  .bg2 {
    position: fixed;
    bottom: -70px;
    left: -88px;
    z-index: -1;
    display: none;
  }

  .terms-section {
    h4 {
      font-size: 13px;
      cursor: pointer;

      a {
        color: $curriki-main-blue;
        font-weight: bold;
        font-family: $rubik, sans-serif;
        text-decoration: underline !important;
        font-size: 13px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }

    .pdf-social {
      display: flex;
      width: 60%;
      justify-content: space-between;
      cursor: pointer;
      font-size: 11px;
      color: $mine-shaft;
      font-weight: bold;

      i {
        color: #f60018;
        padding-right: 7px;
      }
    }

    img {
      max-width: 300px;
      height: 150px;
      margin: 0 auto;
      display: block;
    }

    .fa-times {
      color: #4646c4;
      cursor: pointer;
      text-align: right;
      float: right;
    }

    @media (max-width: 480px) {
      padding: 20px 30px;
      margin: 0 15px;
    }

    a {
      font-size: 12px;
      color: #7b7b7b;
      padding-left: 5px;
      font-weight: 600;
    }

    .form-group.checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;

      .fa {
        padding-right: 5px;
        position: initial;
      }

      .checkbox {
        cursor: pointer;
      }

      @media (max-width: 480px) {
        width: 100%;
      }
    }

    h1 {
      font-size: 30px;
      font-weight: 600;
      margin: 16px 0;
      color: $curriki-main-blue;
    }

    h3 {
      font-size: 1.2rem;
    }

    h4 {
      color: $curriki-main-blue;
      font-weight: bold;
      margin: 35px 0 15px;
    }

    button {
      background: #4646c4 0 0 no-repeat padding-box;
      width: 100%;
      height: 50px;
      border-color: #4646c4;
      font-weight: 400;
      font-size: 19px;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 0.76px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .reset-password-wrapper {
    .form-group {
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-inline--fa {
        z-index: 1;
      }
      .input-box {
        padding-left: 38px;
      }
    }
  }
}

.hr-spacer {
  text-align: center;
  border-bottom: solid 1px black;
  height: 11px;
  margin-bottom: 2em;
  margin-top: 2em;
}

.hr-spacer span {
  font-size: 15px;
  background-color: white;
  padding-left: 1em;
  padding-right: 1em;
}

// Password Change
.password_detail {
  span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px !important;

    color: #515151 !important;
    margin-bottom: 0px !important;
  }
  ul {
    margin-left: 16px;
    li {
      color: #515151;
      margin-bottom: 4px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .icon_check {
    // color: #515151;
    // width: 8px;
    // height: 6px;
    margin-right: 8px;
  }
}
.password_icon_detial_div {
  display: flex;
  align-items: center;
}
#email_error_input_field_div {
  input {
    border: 1px solid red !important;
  }
}
