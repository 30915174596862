@import 'assets/css/index';

.share-project-preview-url.project-share-check {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  nav {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 10px;
    .nav-link {
      font-size: 18px;
      font-weight: 500 !important;
      border: none !important;
      margin-bottom: 0px !important;
      color: #084892 !important;
    }
    a.active {
      color: #084892 !important;
      background-color: #fff !important;
      border-bottom: 4px solid #084892 !important;
    }
  }

  h1 {
    font-size: 1rem;
    color: #515151 !important;
  }

  h3 {
    text-align: center;
    padding-bottom: 10px;

    strong {
      text-decoration: underline;
    }
  }

  .tab-pane {
    a {
      flex: auto;

      input {
        width: 100%;
      }
    }
  }

  input {
    flex: auto;
    border: none;
    cursor: pointer;
    color: #2e8df5 !important;
  }

  i {
    padding-left: 10px;
    cursor: pointer;

    &:hover {
      color: #007bff;
    }
  }

  .google-script {
    display: none;
  }

  .margin-bottom-20 {
    margin-bottom: 7px;
    font-size: 12px;

    .margin-left-20 {
      padding-left: 10px;
    }
  }
  .share-popup-btn {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding-top: 1rem;
    .close-btn {
      text-align: center;

      button {
        border: 0;
        border-radius: 0.25em;
        color: #fff;
        font-size: 1.0625em;
        display: inline-block;
        border-left-color: rgb(48, 133, 214);
        border-right-color: rgb(48, 133, 214);
        margin: 0.3125em;
        padding: 5px 30px;
        box-shadow: none;
        font-weight: 500;
        cursor: pointer;
        background: #084892;
        box-shadow: 0px 2px 6px 1px rgba(8, 72, 146, 0.16);
        border-radius: 18px;
      }
    }
  }
}
