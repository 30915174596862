:root {
  --main-primary-color: #084892;
  --main-secondary-color: #f8af2c;
  --main-paragraph-text-color: #515151;
  --main-white-color: #ffffff;
  --main-black-color: #000000;
  --main-anchor-tag-color: #007bff;
  --main-heading-border: #f8af2c;
  --main-update-permissions-color: #087a2e;
  --main-continue-btn-color: #2ecf43;
  --main-left-btn-color: #5952c6;
  --main-projects-card-color: #607a9b;
  --main-user-text-color: #2f2f2f;
  --maim-playlist-border-color: #dddddd;
  --main-search-input-color: #959595;
  --main-alert-success-color: #346214;
  --main-alert-warning-color: #856404;
  --main-heading-font: rubic;
  --main-text-font: Open Sans, sans-serif;
  --main-bg-transparent: transparent;
}

/* Table of content

1. sidebar --- 15
2. header  --- 22
3. footer  --- 118
4. My Projects  --- 130
5. My Projects Search Library  --- 147
6. Adnin Panel  --- 166
7. My Interactive videos  --- 435
8. Team
9.Auth
*/

.project-loading {
  background: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
  color: #fff !important;
}
.project-loading.Toastify__progress-bar {
  background: var(--main-paragraph-text-color) !important;
}

.alert.alert-primary,
.alert.alert-info,
.alert.alert-danger,
.alert.alert-warning {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.admin-panel .form-new-popup-admin .cross-all-pop {
  color: var(--main-paragraph-text-color) !important;
}
.admin-panel .table-data table .admin-panel-rows .view-all {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.admin-panel .table-data table #filter-dropdown-table-id .dropdown button {
  font-family: var(--main-text-font) !important;
}

/* sidebar branding */

.sidebar-all .sidebar-headings {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

/* header branding */

.dropdown-multitenancy .dropdown-toggle .text {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.header-icon-text {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

/* CurrikiStudio dropdown branding */

.top-header .navbar-link li .dropdown-menu h2.title {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.dropdown-multitenancy a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.dropdown-multitenancy p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.search-div .overlay-search-form .form-group .keywords-de {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

/* help dropdown branding */

.helpcenter .heading-info h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.helpcenter .all-help h3,
p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.add-team-projects .organization-name {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.add-team-projects .team-projects-top-section .back-to-section span,
.add-team-projects .title-image .title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-team-projects .team-projects-top-section .back-to-section svg {
  color: var(--main-primary-color) !important;
}
.account-page .title,
.account-page .panel-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.account-page .account-panel-header-row {
  border-left-color: var(--main-primary-color) !important;
}

.account-page .submit {
  background: var(--main-secondary-color) !important;
  border-color: var(--main-secondary-color) !important;
  font-family: var(--main-heading-font) !important;
}
/*  Notifications dropdown branding */

.top-header .notification-bar .user-dropdown .scroll-notification .header-data h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.top-header .notification-bar .notification-status {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.top-header .notification-bar .user-dropdown .scroll-notification p,
.curriki-utility-startpage .startpage-section .create-project-card .create-project-popup .popup-discription,
.curriki-utility-startpage .startpage-section .create-project-card .create-project-popup .lists .list-text {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.notification-area .timer {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.notification-area .user-detail p a,
.curriki-utility-startpage .startpage-section .create-project-card .create-project-popup .popup-use-project {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.top-header .notification-bar .user-dropdown .btn-all-notification .all-notification,
.top-header .notification-bar .user-dropdown .scroll-notification p a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.modal-dialog .modal-content .modal-body .container .row.mt-4 .remove-primary {
  background-color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
/* My Profile branding */

.user-dropdown-item-name .name,
.curriki-utility-startpage .startpage-section .section-detail ul li,
.curriki-utility-startpage .startpage-section .section-detail h6,
.curriki-utility-startpage .startpage-section .section-detail .help-text {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.add-video-form .add-video-layout-formik .komodo-go-to-about .komodo-go-to-details .record-box .record-text {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.user-dropdown-item-name .email {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.user-dropdown-item {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.noovo-group-title label {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

/* footer branding */

.footer-img-section a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.footer-img-section span,
.curriki-utility-startpage .startpage-section .section-detail .section-heading-one,
.curriki-utility-startpage .startpage-section .section-detail .create-section-h2-space,
.curriki-utility-startpage .startpage-section .section-detail h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

/* My Projects branding */

.add-video-form .add-video-layout-formik .komodo-go-to-about .komodo-go-to-details .detais-heading {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-video-form .add-video-layout-formik .komodo-go-to-about .komodo-go-to-details .detail-text {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.curriki-utility-headings.paragraph-two {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.curriki-utility-headings,
.curriki-utility-startpage .startpage-section .create-project-card .create-project-popup .popup-heading {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}

.project-headline .top-heading-detail {
  color: var(--main-paragraph-text-color) !important;
}

.modal-body .disclaimer {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.modal-body .disclaimer strong {
  color: var(--main-paragraph-text-color) !important;
}

.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .interactive-video-heading-two {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-videoTag .layout-add-activity-title h3 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
/* .add-activity-form .upload-back-button .back-button p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
  background-color: var(--main-white-color) !important;
}
.add-activity-form .upload-back-button .back-button {
  background-color: var(--main-white-color) !important;
  border-color: none !important;
} */
#back-button-none-bg {
  background-color: var(--main-white-color) !important;
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-activity-form .upload-back-button .back-button p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.curriki-theme-secondary-button {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;

  border-color: var(--main-primary-color) !important;
}

.curriki-utility-uploadfile .uploadfile-box button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.curriki-theme-secondary-button:hover {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}

.form-new-popup-myvideo svg {
  color: var(--main-paragraph-text-color) !important;
}

.myvideomain .inner-content .video-cards-detail .Add-video-interaction-section span {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.myvideomain .inner-content .video-cards-detail .Add-video-interaction-section {
  /* background-color: var(--main-primary-color) !important;
  opacity: 0.1; */
}

.curriki-utility-startpage .startpage-section .section-btn {
  /* background-color: var(--main-primary-color) !important;
  opacity: 0.1; */
}
.curriki-utility-startpage .startpage-section .section-btn span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.myvideomain .inner-content .video-cards-top-search-filter .move_activities .move_text {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.curriki-utility-addvideo-card .apply-check-video:before {
  background: var(--main-primary-color);
}
.myvideomain .inner-content .video-cards-top-search-filter .move_activities input {
  accent-color: var(--main-primary-color) !important;
}
.curriki-utility-addvideo-card .addvideo-card-top .addvideo-card-dropdown input {
  accent-color: var(--main-primary-color) !important;
}
/* My Projects Search Library branding */

.search-div h4 {
  color: var(--main-primary-color);
  font-family: var(--main-text-font);
}

.search-div .overlay-search-form .form-group .radio-btns label {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font);
}

.search-div .overlay-search-form .form-group input,
.search-div .overlay-search-form .form-group select {
  color: var(--main-paragraph-text-color);
  font-family: var(--main-text-font);
}

.project-headline .search-main-relaced .search-block {
  border-color: var(--main-primary-color) !important;
}

.project-headline .search-main-relaced .search-block .fa-chevron-down {
  color: var(--main-primary-color) !important;
}

.project-headline .search-main-relaced .search-block input {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.inner-content .main-tabs .nav-item.active {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
}

.activity-layout-form .layout-cards-process-btn .explore-upload-buttons .ex-up-button p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.upload-thumbnail.check .upload_placeholder .gallery p,
.upload-thumbnail.check .upload_placeholder .pexel p {
  font-family: var(--main-heading-font) !important;
}

/* My projects main card branding */

.main-myproject-card .myproject-card-top .myproject-card-title h2 {
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
}

.main-myproject-card .myproject-card-top .myproject-card-title h2:hover {
  /* color: var(--main-heading-border) !important; */
  color: var(--main-secondary-color) !important;
}

.main-myproject-card .myproject-card-detail p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.project-headline-section .paragraph p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.main-myproject-card .updated-date {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.project-headline-section .project-share-previews .project-preview .dropdown-item {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}
.project-headline-section .project-share-previews .project-preview .dropdown-item:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}

.project-headline-section .project-share-previews .share-button {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.alert-success {
  color: var(--main-alert-success-color) !important;
  font-family: var(--main-heading-font) !important;
}

.project-headline-section .project-share-previews .share-button .link-btn span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.project-headline-section .new-playlist .dropdown .paragraph-two {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.project-headline-section .new-playlist .dropdown .dropdown-menu.show .dropdown-item {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.new-playlist .dropdown .btn-primary {
  font-family: var(--main-text-font) !important;
}
.library-status .requested {
  font-family: var(--main-heading-font) !important;
}
.playlist-header-icon-section .hover-text .span-show {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.playlist-no-resource {
  font-family: var(--main-text-font) !important;
}

.project-headline-section .new-playlist .dropdown .show > .btn-primary.dropdown-toggle,
.content .new-playlister button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-color: var(--main-primary-color) !important;
}
.team-project-btns.whiteboard button:first-child {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-color: var(--main-primary-color) !important;
}

.board-custom .list .playlist-header-name .playlist-title-wrapper span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.playlist-resources .resource-card-wrapper .title .playlist-resource-title {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.team-modal .modal-body .satelliteteaminfo {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.playlist-dropdown.check .dropdown-menu a,
.activity-options-wrapper.check .dropdown-menu a,
.inner-form-content-box .activity-layout-form .layout-cards-process-btn .layout-process-btn .nav .nav-item {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.main-myproject-card .myproject-card-add-share button .textinButton {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.dropdown .btn-primary {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom-color: var(--main-primary-color) !important;
}
.activity-layout-form .layout-cards-process-btn .activity-layout-cards .activity-layoutCard-active {
  border-bottom-color: var(--main-secondary-color) !important;
}

.activity-layout-form .layout-cards-process-btn .activity-layout-cards .curriki-utility-layoutcard:hover {
  border-bottom-color: var(--main-secondary-color) !important;
}

.activity-layout-form .layout-cards-process-btn .activity-layout-cards .curriki-utility-layoutcard p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.inner-form-content-box .activity-layout-form .layout-cards-process-btn .layout-process-btn .nav .nav-item.active {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom-color: var(--main-primary-color) !important;
}

.activity-layout-paragraph .curriki-utility-headings.paragraph-two {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.search-card-singleActivity .dropdown-activity-select .dropdown-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-primary-color) !important;
}

.site-container-preview .container .project-details .project-title-desc .project-title h2 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.site-container-preview .container .project-details .project-title-desc .project.description .expandiv {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.search-card-singleActivity .dropdown-content-select label {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.sce_cont .configuration .config-content .go-back-button-preview {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.scene .sce_cont .configuration .shared-link {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.react-confirm-alert .share-project-preview-url.project-share-check nav a.active {
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  border-bottom-color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.react-confirm-alert .share-project-preview-url.project-share-check nav .nav-link {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.react-confirm-alert .share-project-preview-url.project-share-check h1 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.react-confirm-alert .share-project-preview-url.project-share-check .tab-pane a input,
.react-confirm-alert .share-project-preview-url.project-share-check input {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.playlist-div .playlist-title-div .playlist-title-card,
.playlist-div .all-playlist.check-custom .accordion {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom-color: var(--maim-playlist-border-color) !important;
}

.playlist-div .playlist-title-div .title-md:before {
  background: var(--main-secondary-color) !important;
}

.all-playlist.check-custom .playlist-accordion .plydet {
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
}
.project-sample-share .btn-back {
  background: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
.fav-project-title-heading {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.scene .expandiv {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.playlist-title-div .title-md {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.organization-name {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.activity-header .title-activity {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.activity-header .back-to-project {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.team-controller .search-and-filters .search-bar input {
  color: var(--main-paragraph-text-color) !important;
  border-bottom: 2px solid var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.curriki-utility-teamproject-card .select-project-status:before {
  background: linear-gradient(180deg, var(--main-primary-color) 0%, var(--main-primary-color) 100%) !important;
  opacity: 0.8;
}
.update-title-tag {
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
#layout-formik-select-id-btn button {
  /* color: var(--main-paragraph-text-color) !important; */
  color: var(--main-search-input-color) !important;
  border: 1px solid var(--main-paragraph-text-color) !important;
}
.css-1vr111p-option {
  color: var(--main-paragraph-text-color) !important;
}
.css-1vr111p-option input[type='checkbox'] {
  border-color: var(--main-paragraph-text-color) !important;
}
.css-1qprcsu-option input[type='checkbox'] {
  accent-color: var(--main-paragraph-text-color) !important;
  /* border-color: var(--main-paragraph-text-color) !important; */
}
.my-project-cards-top-search-filter .search-bar input {
  border-bottom: 1px solid var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.curriki-utility-teamproject-card .teamproject-card-top .teamproject-card-title h2 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
}
/* playlist preview */

.preview.iframe-height-resource .container-flex-upper {
  background-color: var(--main-primary-color) !important;
}

.preview.iframe-height-resource .container-flex-upper .project-title {
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}

.preview.iframe-height-resource .container-flex-upper svg {
  color: var(--main-white-color) !important;
}

.preview.iframe-height-resource .flex-container-preview h3 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.preview.iframe-height-resource .flex-container-preview .act-top-header .heading-wrapper .main-heading h3 span {
  color: var(--main-user-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.preview.iframe-height-resource .flex-container-preview .back-header .right-control.vd-controls .slider-btn .slider-hover-section a svg,
.preview.iframe-height-resource .flex-container-preview .back-header .btn-expand-collapse svg {
  color: var(--main-primary-color) !important;
}

.flex-container.previews .right-sidegolf-info nav a.nav-item.active {
  color: var(--main-black-color) !important;
  border-bottom-color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.hover-control-caption span {
  color: var(--main-black-color) !important;
  font-family: var(--main-text-font) !important;
}

.main-page-content.preview .all-laylist-oracle li {
  color: var(--main-user-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.right-sidegolf-info .all-laylist-oracle .preview-activity-dropdown .resource-dropdown .dropdown-menu a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.playlist-add-res-button .add-resource-to-playlist-btn {
  color: var(--main-primary-color) !important;
}
.h5p-iframe .h5p-actions > .h5p-button {
  color: var(--main-paragraph-text-color) !important;
}

/* Favorite Projects , Sample Projects and Team Projects cards */

.program-tile .program-content h3 a {
  color: var(--main-projects-card-color) !important;
  font-family: var(--main-text-font) !important;
}

.program-tile .program-content .lessons-duration {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.add-activity-layout-formik-videoTag .add-activity-layout-formik .curriki-utility-headingThree h3,
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-videoTag .layout-add-activity-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-title-formik-textField .textField-title h3,
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-title-formik-textField .textField-detailText p {
  color: var(--main-paragraph-text-color) !important;
  /* font-family: var(--main-text-font) !important; */
  font-family: var(--main-heading-font) !important;
}
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .dec-title-formik-textField span {
  color: var(--main-paragraph-text-color) !important;

  font-family: var(--main-heading-font) !important;
}
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-formik-select .formik-select .formik-select-title {
  font-family: var(--main-heading-font) !important;
}

.modal-content .modal-body h5 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.modal-content .modal-body p {
  color: var(--main-paragraph-text-color) !important;
  /* font-family: var(--main-heading-font) !important; */
}
.img-upload-form p {
  font-family: var(--main-heading-font) !important;
}
.swal2-popup .swal2-html-container p {
  font-family: var(--main-heading-font) !important;
}

.add-team-page .add-team-detail .team-detail p {
  font-family: var(--main-text-font) !important;
}
/* Admin Panel branding */

.utility.curriki-Breadcrump a {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .role-dropdown {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font);
}
.admin-panel .main-heading .secand-heading h2 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font);
}

.admin-panel .parent-organization-detail h3 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font);
}
.admin-panel .parent-organization-detail p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font);
}

.admin-panel .controller .search-bar input,
::placeholder {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font);
  border-bottom-color: var(--main-primary-color) !important;
}

.admin-panel .controller .drop-counter,
.filter-dropdown-activityItems {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font);
}

.admin-panel .controller .drop-counter button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font);
  border-color: var(--main-primary-color) !important;
}
#dropdown-basic {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}
.admin-panel .controller .drop-counter .dropdown-menu .dropdown-item,
.filter-dropdown-activityItems .dropdown-menu .dropdown-item {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font);
}

.admin-panel .pagination-top {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font);
}

.admin-panel .table-data table thead tr th {
  color: var(--main-paragraph-text-color) !important;
  /* font-family: var(--main-text-font) !important; */
  font-family: var(--main-heading-font) !important;
}

.admin-panel .table-data table .admin-panel-rows .admin-name-img .admin-name {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .table-data table td {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .filter-dropdown-project button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font);
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu label {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu .authorName-project input {
  color: var(--main-paragraph-text-color) !important;
  border-color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu .from-project span,
.to-project span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu .from-project input,
.to-project input {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu .status-project .library-status span,
.shared-status span,
.curriki-utility-headingText p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.admin-panel .controller .filter-dropdown-project .dropdown-menu .filter-btn-project {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.admin-panel .controller .switch-libreq {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.admin-panel .controller .role-dropdown button {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}

.admin-panel .controller .role-dropdown .dropdown-menu .dropdown-item {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.admin-panel .controller .role-dropdown {
  color: var(--main-primary-color) !important;
}

.admin-panel .parent-organization-detail button {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
  border-color: var(--main-paragraph-text-color) !important;
}

.admin-panel .controller .btn-text button,
.import-user,
.react-confirm-alert .share-project-preview-url.project-share-check .share-popup-btn .close-btn button,
.playlist-modal .modal-footer .add-playlist-btn {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}
.admin-panel .controller .btn-text button:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}
.admin-panel .controller .import-user:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}
.admin-panel .form-new-popup-admin .inner-form-content .create-form .button-group button.cancel:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
  color: var(--main-white-color) !important;
}

.admin-panel nav {
  border-bottom-color: var(--main-primary-color) !important;
}

.admin-panel .inner-content nav .nav-link.active {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
}

.content-wrapper .inner-content nav .nav-link {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.tab-content .tab-pane .module-content .nav-link {
  color: var(--main-primary-color) !important;
  background-color: var(--main-bg-transparent) !important;
  border-bottom-color: var(--main-primary-color) !important;
}

.tab-content .module-content .nav-tabs .nav-link.active {
  border-bottom: 4px solid var(--main-primary-color) !important;
}

.admin-panel .pagination > .active > a,
.preview-layout-model .pagination > .active > a {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}
.pagination_state {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}

.admin-panel .pagination > li > a,
.preview-layout-model .pagination > li > a {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.pagination > li > a {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  border: 1px solid var(--main-primary-color) !important;
}

ul.pagination .page-item.active .page-link {
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}
.admin-panel .table-data table .filter-dropdown-table .dropdown-menu .dropdown-item {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.project-dropdown.check .dropdown-menu a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.admin-panel .user-roles h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.role-permission-tab-name .nav-item .nav-link {
  color: var(--main-paragraph-text-color) !important;
  /* font-family: var(--main-text-font) !important; */
  font-family: var(--main-heading-font) !important;
}

.roles-permission-tab #role-permission-tab-id .nav-link.active {
  /* font-family: var(--main-text-font) !important; */
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}

.custom-select-style-for-sub p {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.custom-select-style-for-sub h6 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.dynamic-roles .card-body .permission h6 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.detail-permission-tab .all-permission-heading h6 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.media-section .sources-section .sources-options .sources-sub .media-version-options .h5p-title-formik-textField select {
  font-family: var(--main-text-font) !important;
}

.admin-panel .form-new-popup-admin .inner-form-content .create-form .form-group-create .roles-permission-tab-row select {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.custom-select-style-for-sub select {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}

.dynamic-roles-title-btn .update-permission h5 {
  font-family: var(--main-text-font) !important;
  color: var(--main-update-permissions-color) !important;
}

.admin-panel nav .nav-link {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
}
.admin-panel .form-new-popup-admin .inner-form-content .create-form .nav .nav-link {
  border-color: var(--main-primary-color) !important;
}
.admin-panel nav .nav-link.active {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  border-color: var(--main-primary-color) !important;
}
.tab-section .tab-tospp-section .tab-inner-tospp-section .tab_inner-tossp_header h1 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.tab-section .tab-tospp-section .Border-bottom {
  border-bottom-color: var(--main-secondary-color) !important;
}
.tab-section .tab-tossp-detail {
  border-bottom-color: var(--main-secondary-color) !important;
}
.tab-section .tab-inner-section .tab-middle-optional-title h3 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .interactive-video-heading-two {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.tab_inner_header h1 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.tab_inner_color_section .tab_inner_color > div input[type='text'] {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}
.tab_inner_font_section .tab_inner_font_primary select {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}
.model-footer-detail .detail-auther p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.model-footer-detail .detail-auther span {
  color: var(--main-paragraph-text-color) !important;
}

.tab_inner_header button span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.tab-section .tab-inner-section .tab-theming-section .button-group-favicon button.favicon {
  color: var(--main-primary-color) !important;
  background: var(--main-white-color) !important;
  border-color: var(--main-primary-color) !important;
}

.tab-section .tab-inner-section .organization-own-select-radio .form-check .form-check-label.radio-custom-label {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.tab_inner_color_section .tab_inner_color h4,
.tab_inner_font_section .tab_inner_font_primary h4 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.media-section .sources-section h3 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.media-section .sources-section .sources-options .media-field-checkbox .span-heading {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.checkbox_section_media .bg-minus:checked + span {
  background-color: var(--main-primary-color) !important;
}
.media-section .sources-section .sources-options .media-field-checkbox .span-sub {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

#span-sub-selected {
  color: var(--main-primary-color) !important;
}
.checkbox_section_media .bg-tick:checked + span {
  background-color: var(--main-primary-color) !important;
}

.curriki-utility-addvideo-card .addvideo-card-add-share-options-ind {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
#meta-style-td-id span {
  font-family: var(--main-heading-font) !important;
}
#filter-dropdown-user-id-style button {
  font-family: var(--main-text-font) !important;
}
.remove-user-modal h1 {
  font-family: var(--main-heading-font) !important;
}
/* Edit organization */
#custom-toggle-button-id-br-style h3 {
  font-family: var(--main-text-font) !important;
}
.admin-panel .form-new-popup-admin .inner-form-content .create-form h2,
.create-user-modal-header .modal-title,
.add-video-form .add-video-title-select .add-video-title h2,
.add-describevideo-form .curriki-utility-headingTwo h2,
.resource-modal h1 span,
.activity-layout-form .activity-layout-title h2,
.playlist-modal h1 span,
.curriki-utility-headingTwo h2 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom-color: var(--main-secondary-color) !important;
}
.create-user-modal-header .modal-title {
  text-decoration: underline var(--main-secondary-color) !important;
}
.create-user-modal-header .close {
  color: var(--main-paragraph-text-color) !important;
}
.admin-panel .form-new-popup-admin .inner-form-content .create-form .form-group-create h3,
.tos-pp .radio-custom-label,
.email-check-form label,
.create-program-wrapper label h2,
.activity-layout-form .radio-group .radio-button label,
.playlist-modal .modal-body .playlistName {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.admin-panel .form-new-popup-admin .inner-form-content .create-form .form-group-create input,
textarea,
.form-control,
.create-program-wrapper .create-playlist-form .project-name input,
.form-control,
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-title-formik-textField input {
  /* color: var(--main-black-color) !important; */
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}

.admin-panel .form-new-popup-admin .inner-form-content .create-form .form-group-create select,
.add-activity-form .add-activity-title-select .activity-title-change-layout select {
  color: var(--main-black-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}

.tos-pp .radio-custom + .radio-custom-label:before {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
}

.tos-pp .radio-custom:checked + .radio-custom-label:before {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color);
}

.tos-pp .tos-pss-container button {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
  border-color: var(--main-white-color) !important;
}

.create-form .tos-pp .nav-link,
.create-form .tos-pp .nav-link.active {
  color: var(--main-primary-color) !important;
  border-bottom-color: var(--main-primary-color) !important;
}
.admin-panel .controller .filter-dropdown-activityItems button {
  border-color: var(--main-primary-color) !important;
}
/* .admin-panel .form-new-popup-admin .inner-form-content .create-form .button-group button svg {
  color: var(--main-white-color) !important;
} */
.admin-panel .form-new-popup-admin .inner-form-content .create-form .button-group button,
.email-check-form .addButton,
.curriki-theme-primary-button,
.interactive-btns .save-close .saveclosemodel,
.create-program-wrapper .create-playlist-form .create-project-template-wrapper .create-project-submit-btn,
.btns-margin button {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
}

.admin-panel .form-new-popup-admin .inner-form-content .create-form .button-group button:hover,
.email-check-form .addButton:hover,
.curriki-theme-primary-button:hover,
.interactive-btns .save-close .saveclosemodel:hover,
.create-program-wrapper .create-playlist-form .create-project-template-wrapper .create-project-submit-btn:hover,
.btns-margin button:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}

.admin-panel .form-new-popup-admin .inner-form-content .create-form .button-group button.cancel,
.form-horizontal .interactive-btns .cancel .backclosemodel {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  color: var(--main-primary-color) !important;
}

.upload-btn,
.describe-video-upload-section .curriki-utility-uploadimageV2 .uploadimage-option button,
.upload-thumbnail.check .upload_placeholder .pexel p,
.upload-thumbnail.check .upload_placeholder .pexel,
.upload-thumbnail.check .upload_placeholder .gallery p,
.upload-thumbnail.check .upload_placeholder .gallery,
.curriki-utility-uploadimageV2 .uploadimage-option button {
  border-color: var(--main-paragraph-text-color) !important;
  color: var(--main-paragraph-text-color) !important;
}
.tab-section .edit-org-save-btn {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}

/* My Interactive videos branding */

.curriki-utility-TopHeading p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.curriki-utility-TopHeading .curriki-imageHeading h1 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.curriki-utility-headingText p {
  color: var(--main-paragraph-text-color) !important;
  /* font-family: var(--main-heading-font) !important; */
  font-family: var(--main-text-font) !important;
}
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .layout-formik-select .formik-select .formik-select-title p {
  font-family: var(--main-heading-font) !important;
}
.curriki-utility-uploadimageV2 h3 {
  font-family: var(--main-heading-font) !important;
}
.activity-layout-form .upload-back-button .back-button p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.search-card-singleActivity .dropdown-activity-select .dropdown-activity-select-inner-div .filter_title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.activity-layout-form .layout-cards-process-btn .activity-layout-cards .card-btn .btn {
  font-family: var(--main-text-font) !important;
}
.back-button p {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.myvideomain .inner-content .my-interactive-videos .top-tabs {
  border-color: var(--main-primary-color) !important;
}

.myvideomain .inner-content .my-interactive-videos .top-tabs .nav-link {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}

.myvideomain .inner-content .my-interactive-videos .top-tabs .nav-link.active {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.myvideomain .inner-content .video-cards-top-search-filter .search-bar input,
.search-card-singleActivity .input-group input {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
  border-bottom-color: var(--main-primary-color) !important;
}

.add-video-tabs .curriki-utility-tabs .tab-text p,
.add-describevideo-tabs .curriki-utility-tabs .tab-text p,
.interactive-video-H5P .curriki-utility-tabs .tab-text p,
.curriki-utility-tabs .tab-text p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.add-video-tabs .curriki-utility-tabs .activeTabs,
.add-describevideo-tabs .curriki-utility-tabs .activeTabs,
.interactive-video-H5P .curriki-utility-tabs .activeTabs,
.curriki-utility-tabs .activeTabs {
  /* background-color: var(--main-heading-border) !important; */
  background-color: var(--main-secondary-color) !important;
}

.add-video-tabs .curriki-utility-tabs .tabs {
  color: var(--main-paragraph-text-color) !important;
}

.add-video-form .add-video-layout-formik .layout-title-formik-textField input {
  color: var(--main-black-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}

.add-video-form .add-video-layout-formik .layout-title-formik-textField {
  color: var(--main-paragraph-text-color) !important;
}

.add-video-form .add-video-form-tabs .main-tabs {
  border-bottom-color: var(--main-primary-color) !important;
}

.form-new-popup-myvideo .add-video-form .add-video-form-tabs .main-tabs .nav-item.active,
.form-new-popup-myvideo .add-video-form .add-video-form-tabs .nav-tabs .nav-link {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom-color: var(--main-primary-color) !important;
}

.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .dec-title-formik-textField span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .dec-title-formik-textField p {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .dec-title-formik-textField input,
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .layout-formik-select .formik-select select,
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-formik-select .formik-select select {
  /* color: var(--main-black-color) !important; */
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .layout-formik-select .formik-select .css-1r4vtzz {
  /* color: var(--main-paragraph-text-color) !important; */
  color: var(--main-search-input-color);
  border-color: var(--main-paragraph-text-color) !important;
}
.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .dec-title-formik-textField .textField-title h3 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}
/* .curriki-utility-addvideo-card .addvideo-activity-menu-option .activity-request-tag svg{
  color: var(--main-paragraph-text-color) !important;
} */
.curriki-utility-addvideo-card .addvideo-activity-menu-option .activity-request-tag span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.add-describevideo-form .add-describevideo-section-layout-formik .add-describevideo-layout-formik .dec-title-formik-textField ::placeholder,
.form-control::placeholder,
.add-activity-form .add-activity-layout-formik-videoTag .add-activity-layout-formik .layout-title-formik-textField input::placeholder {
  color: var(--main-paragraph-text-color) !important;
}

.describe-video-upload-section .curriki-utility-uploadimageV2 h3 {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
}

.interactive-video-H5P .alert-primary {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.h5p-brightcoveinteractivevideo-editor .form-manager-head .form-manager-title:first-child .truncatable-text {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}

.curriki-utility-addvideo-card .addvideo-card-top .addvideo-card-title h2 {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
  cursor: pointer;
}
.curriki-utility-addvideo-card .addvideo-card-top .addvideo-card-title:hover h2 {
  color: var(--main-secondary-color) !important;
  text-decoration: underline;
}
.curriki-utility-addvideo-card .addvideo-card-detail p {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
}
.curriki-utility-activity-dropdown .activity-dropdown.check .dropdown-menu a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.curriki-utility-uploadimageV2 .uploadimage-option button {
  font-family: var(--main-heading-font) !important;
}
.curriki-utility-uploadimageV2 .uploadimage-option button:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
  color: var(--main-white-color) !important;
}
.swal2-popup .swal2-actions button.swal2-cancel:hover,
.swal2-actions button.swal2-deny:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
  color: var(--main-white-color) !important;
}
.clone-lti .modal-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.lti-all-project .information-clone-model .clone-searching-section .search-and-filters .search-bar input {
  border-bottom: 2px solid var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
#information-clone-model-parap {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.active-resource-update .active-resource-detail h6 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.active-resource-update .active-resource-detail p span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

.active-resource-update .active-resource-detail .model-name-activity-selection .model-name-activity-playlist span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.activity-project-playlist .activity-project-playlist-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.activity-project-playlist .playlist-title-copy-text .copy-here .copy-here-span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.lti-all-project .information-clone-model .clone-back-option-project span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.lti-all-project .information-clone-model .clone-create-project-headng {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom: 4px solid var(--main-secondary-color) !important;
}
/* .addvideo-card-add-share{
  color: var(--main-user-text-color) !important;
  font-family: var(--main-heading-font) !important;
} */

.alert-warning {
  color: var(--main-alert-warning-color) !important;
  font-family: var(--main-heading-font) !important;
}

.curriki-utility-addvideo-card .activity-update-lib .activity-update {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.curriki-utility-addvideo-card .activity-update-lib .activity-lib {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.curriki-utility-addvideo-card .activity-update-lib .activity-lib span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.addvideo-card-add-share-options .textinButton {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.activity-layout-form .activity-layout-detail h5 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
/* Swal Popup */
.swal2-popup .swal2-actions button.swal2-confirm {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
}

.swal2-popup .swal2-actions button.swal2-cancel,
.swal2-actions button.swal2-deny {
  border-color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.swal2-popup .swal2-html-container,
.swal2-popup .swal2-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.swal2-popup .swal2-icon.swal2-warning {
  border-color: var(--main-secondary-color) !important;
  color: var(--main-secondary-color) !important;
}
/*  */

.preview-layout-model .modal-content .model-top-heading h2,
.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-top-header .NetSuite-heading h2 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}

.preview-layout-model .video-model-tab .nav-menu-heading h3,
.preview-layout-model .video-model-tab-row #role-permission-tab-id .nav-link.active {
  /* color: var(--main-primary-color) !important; */
  font-family: var(--main-text-font) !important;
}

.preview-layout-model .video-model-tab-row #role-permission-tab-id .nav-link {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}

.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-top-header .NetSuite-section-searching .section-input-search {
  border-color: var(--main-primary-color) !important;
}

.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-top-header .NetSuite-section-searching .section-input-search input {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-table table thead tr th,
.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-table table td {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}

.preview-layout-model .detail-permission-tab .for-NetSuite-section .NetSuite-section-table table tr td span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}

/* 8 - Team branding Start */
.library-status .approved {
  color: var(--main-primary-color) !important;
}
/* .add-team-page .right .toggle_btn svg :hover{
  background-color: var(--main-secondary-color) !important;
} */
.teams-page .title {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.add-team-projects .project-tabs .main-tabs.nav.nav-tabs {
  border-bottom: 1px solid var(--main-primary-color) !important;
}

.inner-content .add-team-projects .project-tabs .main-tabs .nav-item {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
  border-color: var(--main-primary-color) !important;
}

.inner-content .add-team-projects .project-tabs .main-tabs .nav-item.active {
  background-color: var(--main-white-color) !important;
  color: var(--main-primary-color) !important;
  border-bottom: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
}

.add-team-projects .flex-button-top .team-controller .search-and-filters .search-bar input {
  border-bottom: 1px solid var(--main-primary-color) !important;
}

.curriki-utility-teamproject-card .project-checkbox-select .checkbox_section .assign-project-radio {
  /* border-color: var(--main-primary-color) !important; */
}
.curriki-utility-teamproject-card .project-checkbox-select .checkbox_section .assign-project-radio .checkmark {
  /* background-color: var(--main-primary-color) !important; */
}
.teams-page .btn-top-page {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
.teams-page .btn-top-page:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}
.back-button {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
.team-card-content .describe {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.team-card-content .sub-title span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.team-card-content .team-member-content .member-mark-container .member-name-mark span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.team-card-content .edit-button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.side-wrapper-team .back-button-main-page {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
.create-team .describe {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.create-team-sidebar .team-sidebar-btn.selected .btn-box span.top-vertical-line {
  background-color: var(--main-primary-color) !important;
}
.create-team-sidebar .team-sidebar-btn.selected .btn-box .number-box span.number {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}
.create-team-sidebar .team-sidebar-btn.selected span.name {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.create-team-wrapper .creation-panel .satelliteteaminfo {
  color: var(--main-paragraph-text-color) !important;
}
.create-team-wrapper .creation-panel .create-team-form .team-info-input > div h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.create-team-continue-btn {
  background-color: var(--main-continue-btn-color) !important;
  /* color: var(--main-white-color) !important; */
  /* C-CFM-S (color) */
  font-family: var(--main-text-font) !important;
}
.team-information .projects-wrapper .project-list .project-content-item .project-title a {
  color: var(--main-anchor-tag-color) !important;
  font-family: var(--main-text-font) !important;
}
.side-wrapper-team-projects .back-button-main-page {
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-text-font) !important;
}
.assign-project-wrapper .assign-projects .assign-project-item .assign-project-radio {
  border-color: var(--main-primary-color) !important;
}
.assign-project-wrapper .assign-projects .assign-project-item .assign-project-title {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.btn-left-page {
  background-color: var(--main-left-btn-color) !important;
  color: var(--main-white-color) !important;
}
.member-manage .team-description .description {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.member-manage .team-information .member-management-wrapper .member-list .member-item .member-info .member-name {
  color: var(--main-user-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.side-wrapper-team .bread-crumb span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.member-manage .team-information .member-management-wrapper .member-list .member-item .member-name-mark span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-text-font) !important;
}
.teams-page .organization-name,
.add-team-page .organization-name {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.teams-page .title-team,
.add-team-page .title-image .title,
.add-team-page .right .right_head h1 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.teams-page .title-team,
.add-team-page .title-image .title,
.add-team-page .right .right_head h1 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.team-card-content .team-title a {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.team-modal h1 span {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
  border-bottom: 2px solid var(--main-secondary-color) !important;
}
.team-modal .modal-body .label-title {
  font-family: var(--main-heading-font) !important;
}
.team-modal .modal-footer .add-team-btn {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.team-modal .modal-footer .add-team-btn:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7 !important;
}
.teams-page .team-controller .search-and-filters .search-bar input {
  border-bottom: 2px solid var(--main-primary-color) !important;
}
.add-team-page .flex-button-top .team-controller .search-and-filters .search-bar input {
  border-bottom: 2px solid var(--main-primary-color) !important;
}
.invite-wrapper .invite-dialog > div .email-input .non-invitee-members .invite-member-item:hover {
  background-color: var(--main-primary-color) !important;
}
.invite-wrapper .invite-dialog > div button:not(:disabled) {
  background: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
  font-family: var(--main-heading-font) !important;
  border-color: var(--main-primary-color) !important;
}
.add-team-page .right .right_label .filter-dropdown-role-team .dropdown-menu .dropdown-item {
  color: var(--main-primary-color) !important;
}

.invite-wrapper .invite-dialog > div .email-input {
  border-color: var(--main-paragraph-text-color) !important;
}
.invite-wrapper .invite-btn,
.invite-wrapper .invite-dialog select {
  border-color: var(--main-paragraph-text-color) !important;
  color: var(--main-primary-color) !important;
  /* font-family: var(--main-heading-font) !important; */
  font-family: var(--main-text-font) !important;
}
.invite-wrapper .invite-dialog h2 {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.invite-wrapper .invite-dialog .selected-option,
.add-team-page .right .right_info h6 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.invite-wrapper .invite-dialog > div .email-input,
.invite-wrapper .invite-btn svg {
  color: var(--main-primary-color) !important;
}
/* Auth */
.auth-page .auth-container nav .nav-link {
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
  border-color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.auth-page .auth-container nav .nav-link.active {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
  border-color: var(--main-primary-color) !important;
}
.auth-page .auth-container .auth-title {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.auth-page .auth-container .auth-Pdescrip {
  color: var(--main-paragraph-text-color);
  font-family: var(--main-text-font);
}
.auth-page .auth-container .input-box {
  color: var(--main-black-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}
.auth-page .auth-container .password-box {
  color: var(--main-black-color) !important;
  font-family: var(--main-text-font) !important;
  border-color: var(--main-paragraph-text-color) !important;
}
.auth-page .auth-container .form-button .submit,
.auth-page .auth-container .form-group .signUp-btn {
  font-family: var(--main-heading-font) !important;
  color: var(--main-white-color) !important;
  background-color: var(--main-primary-color) !important;
}
.auth-page .auth-container .form-group span {
  font-family: var(--main-heading-font) !important;
}
.auth-page .auth-container .form-group .show-password {
  font-family: var(--main-text-font) !important;
}
.auth-page .auth-container .remember-me a,
.auth-page .auth-container .remember-me label {
  font-family: var(--main-text-font) !important;
}
.auth-page .auth-container .login-separator-box .text-separator {
  font-family: var(--main-heading-font) !important;
}
.auth-page .auth-container .termsandcondition {
  font-family: var(--main-text-font) !important;
}
.auth-page .auth-container .google-button div {
  font-family: var(--main-heading-font) !important;
}
.password_detail span {
  font-family: var(--main-text-font) !important;
}
.password_detail ul li {
  font-family: var(--main-text-font) !important;
}
/* Searching */
.search-result-main .current-org-search {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.search-result-main .exp-lib-cnt {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.search-result-main .total-count span {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.search-result-main .search-library .card-header {
  /* font-family: var(--main-text-font) !important; */
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.search-result-main .search-library .card-body .author-label {
  font-family: var(--main-heading-font) !important;
}

.search-result-main .search-library .card-body input {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
  border: 2px solid var(--main-search-input-color) !important;
}
.search-result-main .search-library .card-body input[type='text']:focus {
  box-shadow: 0px 0px 4px 2px var(--main-primary-color) !important;
  border: 1px solid var(--main-primary-color) !important;
}
.search-result-main .search-library .card-body .src-btn {
  font-family: var(--main-heading-font) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}
.search-result-main .refine-search .headline {
  font-family: var(--main-heading-font) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}
.search-result-main .refine-search .card-header {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.search-result-main .refine-search .list-item-keys svg.fa-square {
  border: 2px solid var(--main-primary-color) !important;
}
.search-result-main .refine-search .list-item-keys span {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}
.search-result-main .refine-search .list-item-keys svg.fa-check-square {
  border: 2px solid var(--main-primary-color) !important;
  color: var(--main-primary-color) !important;
}
.search-result-main .right-search nav a.active {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.search-content-by,
.search-content-type {
  font-family: var(--main-text-font) !important;
}
.search-content-by span,
.search-content-type span {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
#right-search-branding-style nav a.active {
  border-bottom: 4px solid var(--main-primary-color) !important;
}
.search-result-main .right-search .nav-link {
  color: var(--main-primary-color) !important;
  font-family: var(--main-text-font) !important;
}
.search-result-main #right-search-branding-style .nav-tabs {
  border-bottom: 1px solid var(--main-primary-color) !important;
}
.search-result-main .right-search .results_search .box h2 {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.search-result-main .right-search .results_search .box .search-content ul li {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
}
.search-result-main .main-tabs .nav-item {
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}
.search-result-main .nav-tabs .nav-link {
  border: 1px solid var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.search-result-main .right-search .results_search .contentbox .btn-fav {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}
.search-result-main .main-tabs .nav-item.active {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
  background-color: var(--main-white-color) !important;
}
.search-result-main .main-tabs .nav-item.active:after {
  display: none !important;
}

.search-result-main .right-search .results_search .box .search-content ul li span {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.search-div .overlay-search-form .form-group .radio-btns label input[type='radio']:after {
  border: 2px solid var(--main-primary-color) !important;
}
.search-div .overlay-search-form .form-group .radio-btns label input[type='radio']:checked:after {
  background-color: var(--main-primary-color) !important;
  border: 2px solid var(--main-primary-color) !important;
}
.search-result-main .search-library .card-body .radio-btns label span {
  color: var(--main-primary-color) !important;
}
.search-result-main .search-library .card-body .radio-btns input[type='radio'] {
  accent-color: var(--main-primary-color) !important;
}
.search-result-main .exp-lib-cnt span {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.curriki-utility-subsearch .subsearch-top-search-filter .activity-counter .drop-counter {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.dropDown-item-name-icon span {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}

/* Button Icons */
.project-headline button .fa-plus {
  color: var(--main-secondary-color) !important;
}
.teams-page .btn-top-page svg {
  color: var(--main-secondary-color) !important;
}
.admin-panel .controller .btn-text button svg {
  color: var(--main-secondary-color) !important;
}
.admin-panel .controller .import-user svg {
  color: var(--main-secondary-color) !important;
}
.team-modal .modal-footer .add-team-btn svg {
  color: var(--main-secondary-color) !important;
}

.project-headline-section .project-share-previews .dropdown-item svg {
  color: var(--main-secondary-color) !important;
}
.search-result-main .search-library .card-body .src-btn svg {
  color: var(--main-secondary-color) !important;
}
.button-submit {
  font-family: var(--main-text-font) !important;
  background-color: var(--main-primary-color) !important;
  color: var(--main-white-color) !important;
}

.Add-my-project-section span {
  font-family: var(--main-heading-font) !important;
  color: var(--main-primary-color) !important;
}
.my-project-cards-top-search-filter .activity-counter .drop-counter {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.search-result-main .total-count {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.myvideomain .inner-content .video-cards-top-search-filter .activity-counter .drop-counter {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}

.curriki-utility-addvideo-card .addvideo-card-add-share .btn-box {
  font-family: var(--main-text-font) !important;
  color: var(--main-secondary-color) !important;
}

.account-page .form-group label {
  font-family: var(--main-text-font) !important;
  color: var(--main-primary-color) !important;
}
.account-page .form-group input,
.account-page .form-group select {
  font-family: var(--main-heading-font) !important;
  color: var(--main-paragraph-text-color) !important;
}

.add-team-page .team-back-to-option .team-back-to-option-link {
  font-family: var(--main-text-font) !important;
  color: var(--main-paragraph-text-color) !important;
}

/* For Browse Image */
.thumbnails-modal .thumbnails-modal-body .thumbnails-close .thumbnails-heading {
  color: var(--main-primary-color) !important;
}
.thumbnails-modal .thumbnails-modal-body .thumbnails-tabs .nav-link.active {
  background-color: var(--main-primary-color) !important;
  border: 1px solid var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.thumbnails-modal .thumbnails-modal-body .thumbnails-tabs .nav-link {
  color: var(--main-paragraph-text-color) !important;

  font-family: var(--main-heading-font) !important;
}
.filter_smithsonian_section .smithsonian_filter_menu_detail .refine-search .card-detail-header .card-header {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.search-box .smithsonian-clear button {
  color: var(--main-primary-color) !important;
  font-family: var(--main-heading-font) !important;
}
.filter_smithsonian_section .smithsonian_filter_menu_detail .refine-search .list-item-keys span {
  color: var(--main-paragraph-text-color) !important;
  font-family: var(--main-heading-font) !important;
}
.filter_smithsonian_section .smithsonian_filter_menu_detail .refine-search .card-detail-list-options .card-detail-list-search-icon .card-detail-list-search {
  border-bottom: 1px solid var(--main-paragraph-text-color) !important ;
}
.read-more-pexel {
  color: var(--main-primary-color) !important;
}
.thumbnails-img-box-pexels .thumbnails-watermark span {
  background-color: var(--main-primary-color) !important;
  opacity: 0.7;
}
