@import "assets/css/index";

.container-fluid {
  padding: 0 !important;
}

.top-header {
  position: fixed !important;
  width: 100%;
  height: 80px;
  box-shadow: 0 5px 6px #00000026;
  z-index: 111;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  top: 0;
  padding: 0 32px;
}

.top-header {
  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &:after {
      border-top-color: gray;
    }
  }

  .group-search-logo {
    display: flex;
    align-items: center;

    .tophd_left {
      .top_logo {
        width: 300px;
        height: 80px;

        .nav-logo {
          width: 132px;
          height: 100%;
        }
      }
    }
  }

  .notification-bar {
    .notification-alert {
      position: relative;

      .alert-added {
        width: 10px;
        height: 10px;
        background-color: #d54646;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .notification-status {
      font: normal normal 600 14px/20px $rubik;
      letter-spacing: 0;
      color: #8f8fa1;
      padding: 15px 0 5px 25px;
    }

    .user-dropdown {
      // width: 480px !important;
      // box-shadow: 0 0 99px #00000021;
      // border-radius: 3px;

      width: 470px !important;
      height: 553px;
      left: calc(50% - 281px / 2 + 2.5px);
      top: calc(50% - 290px / 2 - 4px);
      background: #ffffff;
      box-shadow: 1px 20px 50px 1px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      overflow: hidden;

      .scroll-notification {
        max-height: 480px;
        overflow-y: auto;

        .header-data {
          display: flex;
          justify-content: space-between;
          padding: 15px 25px;
          border-bottom: 1px solid #f2f0f0;

          h2 {
            font: normal normal 600 18px/20px $rubik;
            letter-spacing: 0;
            color: #0f0f0f;
            margin-bottom: 0;
          }

          h3 {
            font: normal normal 600 14px/20px $rubik;
            letter-spacing: 0;
            color: #5250c1;
            margin-bottom: 0;
          }
        }

        .dropdown-item {
          padding: 0;

          &:nth-of-type(odd) {
            .user-detail .user-icons {
              background: #c3c0e7 0% 0% no-repeat padding-box;
              color: #6d629a;
            }
          }
        }

        p {
          white-space: pre-wrap;
        }
      }

      .btn-all-notification {
        border-top: 1px solid #f2f0f0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0;

        .all-notification {
          text-decoration: underline;
          font: normal normal 600 14px/20px $rubik;
          color: #5250c1;
          width: 100%;
        }

        .notification-setting {
          font: normal normal 600 14px/20px $rubik;
          letter-spacing: 0;
          color: #5250c1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    h2.title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #515151;
    }
  }

  .navbar-link li .dropdown-menu {
    width: 200px;
    padding: 12px;
    margin-top: -5.5px !important;

    h2.title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #515151;
    }

    a {
      padding: 10px 20px;
    }
  }

  .navbar-link {
    button {
      padding: 0px !important;
    }
  }

  .active {
    .dropdown-menu {
      display: block;
    }
  }

  .hide {
    .dropdown-menu {
      display: none;
    }
  }
}

.search-block {
  position: relative;
  overflow: hidden;
  width: 380px;
  height: 40px;
  margin-right: 0px;

  .searchicon {
    position: absolute;
    top: 11px;
    right: 40px;
    width: 18px;
    cursor: pointer;
  }

  .searchiconSvg {
    position: absolute;
    top: 11px;
    right: 40px;
    width: 18px;
    cursor: pointer;
  }

  .fa-chevron-down {
    position: absolute;
    right: 20px;
    top: 13px;
    color: $fun-blue-1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
  }
}

.search-div {
  align-items: center;
  margin-right: 32px;

  #dropdown-basic {
    background-color: transparent;
    position: initial;
    right: 4px;
    color: $fun-blue-1;
    top: 0px;
    padding: 0;
  }

  .search-block+.dropdown-menu {
    box-shadow: 0 0 20px #00000029;
    border: 1px solid #cfcfcf;
    opacity: 1;
    padding: 30px;
    background: #fff;
    margin-top: 0;
    width: 600px;
    position: absolute;
    right: auto !important;
    left: initial !important;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 615px;
    top: 20px !important;
  }

  .overlay-search-form {
    .form-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      input,
      select {
        border: none;
        width: 100%;
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        border-bottom: 1px solid #cfcfcf;
        letter-spacing: 0;
        color: #636363;
        opacity: 1;
        padding-bottom: 5px;
        background-color: #ffffff;
      }

      .error {
        color: red;
        display: block;
      }

      .keywords-de {
        background-color: $curriki-main-blue;
        color: #fff;
        border-radius: 30px;
        padding: 6px 10px;
        font-size: 12px;
        position: relative;
        margin: 5px 5px 0 0;

        .iocns {
          color: #fff;
          background-color: #ff0000d1;
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: 30px;
          stroke-width: 1px;
          cursor: pointer;
          display: none;

          svg {
            margin: 10px auto;
            display: flex;
          }
        }

        &:hover .iocns {
          display: block;
        }
      }

      input[type="date"],
      input[type="select"] {
        cursor: pointer;
      }

      .radio-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: -10px 0px;

        label {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          line-height: normal;
          color: #636363;
          font-weight: 400;
          cursor: pointer;

          input {
            width: auto;
            margin-right: 10px;
            cursor: pointer;
          }

          input[type="radio"]:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: #fff;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 2px solid $fun-blue-1;
          }

          input[type="radio"]:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $fun-blue-1;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 2px solid $fun-blue-1;
          }
        }
      }
    }
  }

  .dual select,
  .dual input {
    width: 48% !important;
  }

  h4 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0;
    color: $curriki-main-blue;
    text-transform: capitalize;
    opacity: 1;
    padding-bottom: 20px;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
  }

  .form-group.wrap-keyword {
    display: flex;
    justify-content: initial;
    flex-wrap: wrap;
  }

  button {
    font-size: 15px;
    line-height: 20px;
    background: $curriki-main-blue 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    border: none;
    letter-spacing: 0;
    color: #ffffff;
    //width: 102px;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  .dual_activity {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}

.tophd_left {
  width: auto;
  height: 75px;
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 75px;
  }
}

.search-term {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid $fun-blue-1;
  box-sizing: border-box;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
  color: $fun-blue-1;
  padding-left: 16px;
  padding-right: 60px;
  width: 100%;
  height: 100%;
  font-family: $sans;

  &::placeholder {
    color: $fun-blue-1;
  }
}

.header-icon-text {
  font-size: 14px;
  text-align: center;
  color: $fun-blue-1;
  margin-bottom: 0px;
  display: block;
  font-family: $sans;
  padding-top: 3px;
  line-height: 20px;
}

.search-submit {
  position: absolute;
  right: -10px;
  z-index: 99;
  top: 3px;
  border: 0;
  background: transparent;
  font-size: 18px;
  color: #fff;
  width: 27px;
  height: 12px;
  border-radius: 0;
  margin-right: 20px;
}

.dropdown-multitenancy {
  div.text {
    // text-overflow: ellipsis;
    // overflow: hidden;
    // text-align: center;
    // width: 60px;
  }

  .dropdown-toggle {
    color: $fun-blue-1 !important;
    line-height: 0;

    .text {
      font-size: 14px;
      font-family: $sans;
      padding-top: 3px;
      line-height: 20px;
    }

    &:hover,
    &:active,
    &:focus {
      color: $fun-blue-1;
    }
  }

  .dropdown-menu {
    min-width: 398px;
    max-height: 359px;
    background: #ffffff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: none;
    padding: 10px 25px;
    overflow: auto;
  }

  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $fun-blue-1;
    padding: 0;
  }

  .all-tg-lister {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0px;
  }

  p {
    font-weight: normal;
    font-size: 12.8px;
    line-height: 127.85%;
    color: #888888;
    margin-bottom: 0px;
  }
}

.profile-avatar {
  height: 24px;
  background: #084892;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -8px; */
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: 24px;
  margin: 0 auto;
}

.user-dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: $fun-blue-1;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  img {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 -15px;
    // border: 5px solid black;
  }

  svg {
    margin: 0 10px 0 -15px;
  }

  &:active {
    color: white;
  }
}

hr {
  width: 218.5px;
  height: 5px;
  left: calc(50% - 218.5px / 2 - 3.75px);
  top: calc(50% - 0px / 2 + 12px);
  border: 1px solid #e5e5e5;
}

.user-dropdown-item-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;

  .basic-info {
    margin-left: 10px;
  }

  .name {
    // height: 10px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    // border: 1px solid black;
  }

  .email {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #084892;
    // border: 1px solid black;
  }
}

// C-CSS
.mobile-links {
  display: none !important;
}

.top-header.flex-div .dropdown-toggle {
  cursor: pointer;
}

.top-header.flex-div .dropdown-toggle::after {
  content: none;
  cursor: pointer;
}

.navbar-link li .dropdown-menu {
  position: absolute;
  left: auto;
  padding: 0;
  right: 15px;
  margin: 0;
  // margin-top: 3px;
  text-align: left;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 10px 10px;
  width: 405px;
  background: #ffffff;
}

.flex-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.top-info>li>a,
.dropbtn {
  display: block;
  border-radius: 100%;
}

.top-info {
  margin: 0 -15px;
}

.top-info li {
  padding-left: 32px;
}

.top-info>li:first-child>a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  svg {
    color: $fun-blue-1;
    font-size: 28px;
  }

  p {
    padding-top: 2px;
  }
}

.top-header-right {
  padding-right: 40px;
}

.top-info>li>a:hover {
  //  background: #1a73e8;
  //border-color: #1a73e8;
}

.top-info>li>a:hover img {
  // filter: brightness(0) invert(1);
}

/* css for dropdown menu */
.navbar-link li .dropdown-menu.user-dropdown {
  min-width: 230px;
  width: auto;
}

.user-dropdown li {
  border-bottom: 1px solid #a6a6a6;
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 0;
  color: $mine-shaft;
  padding: 15px !important;
}

.user-dropdown li a {
  height: auto;
  line-height: 100%;
  border: 0;
  display: block;
}

.top-info li .user-dropdown li a:hover {
  background: none;
}

/* .navbar-link .top-info {
    position: relative
} */

.menuopen .leftmenu {
  width: 310px;
  overflow: visible;
}

.menuopen .leftmenu:before {
  display: none;
}

body.menuopen {
  position: fixed;
  width: 100%;
}

body.menuopen:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(9, 24, 73, 0.94);
  z-index: 11;
}

.menuopen .leftmenu a.logo {
  display: inline-block;
  opacity: 1;
  transition: all ease-out 0.3s !important;
}

a.logo {
  max-width: 275px;
  float: left;
  margin: 35px 0 0 20px;
  transition: all ease-out 0.3s !important;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 40px;
}

a.logo img {
  width: 100%;
}

/*navigation styles start*/
nav {
  width: 290px;
  clear: both;
  // float: left;
  margin: 130px 0 0 0;
  padding: 0 25px 0 30px;
}

nav ul {
  width: 100%;
  clear: both;
  float: left;
}

nav ul li {
  width: 100%;
  clear: both;
  float: left;
  text-transform: capitalize;
  padding: 15px 0;
}

nav ul li a {
  font-size: 16px;
  color: #607a9b;
  font-family: $rubik, sans-serif;
  line-height: 100%;
  transition: all 0.5s linear;
}

nav ul li:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

nav ul li i {
  font-size: 13px;
  color: #607a9b;
  padding-right: 10px;
}

nav ul li a img {
  display: inline-block;
  padding-right: 10px;
  vertical-align: baseline;
}

nav>ul>li>a:hover,
nav>ul>li.active>a,
.submenu li a:hover,
.submenu li.active a,
.submenu li a:hover i,
.submenu li.active i,
nav>ul>li>a:hover i,
nav>ul>li.active a i {
  color: #1a73e8;
}

nav>ul>li>a:hover img,
nav>ul>li.active>a img {
  filter: invert(70%) sepia(23%) saturate(1975%) hue-rotate(192deg) brightness(108%) contrast(100%);
}

.show {
  display: block;
}

.submenu li i {
  padding-right: 2px;
}

body.menuopen nav ul li.haschild a:after {
  position: absolute;
  opacity: 1;
  transition: all ease-out 3.5s !important;
}

/*nav ul li ul.submenu1 li ul.submenu2 {display: none}*/
.submenu {
  padding-left: 25px;
}

.submenu li {
  text-transform: initial;
}

.submenu li a {
  background: #fafafa;
  border: 1px dashed #bfbfbf;
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 14px;
}

// Media

@media screen and (max-width: 992px) {
  .top-header {
    position: relative;
    padding: 15px;
  }

  .tophd_left {
    padding: 0;
  }

  .top_logo {
    padding-left: 0;
  }

  .navbar-link .top-info {
    margin-right: 50px;
  }

  .navbar-link .navmenu {
    transform: none;
    width: 100%;
    position: relative;
    max-width: 100%;
    left: auto;
    box-shadow: none;
    margin: 1px 0;
    background: #e5f4fb;
    min-width: inherit;
    border: 0;
    padding: 0;

    li {
      padding: 10px;
    }
  }

  .navbar-link nav>ul>li.has-sub {
    position: static;
  }

  .top-info>li {
    padding: 0 10px;
  }
}

@media (max-width: 860px) {
  .tophd_left {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .top-header-right {
    width: 100%;
    background-color: #eeeeee;
    padding: 15px;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
  }

  .top-header {
    padding: 0;
    box-shadow: none;
  }

  .tophd_left {
    margin-bottom: 0;
    padding: 15px;
  }

  .navbar-link .top-info {
    margin-right: 0;
  }
}

@media (max-width: 580px) {
  .navbar-link li .dropdown-menu {
    display: none;
  }

  .navbar-link li .dropdown-menu {
    width: 100%;
    position: relative;
    padding-bottom: 0;
    left: auto;
  }

  .navbar-link {
    width: 100%;
  }

  .top-info.flex-div {
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    width: calc(100% - 50px);
  }

  .top-info.flex-div li:first-child {
    position: absolute;
    top: 0;
    padding: 0 !important;
    margin-top: -55px;
    right: 15px;
    width: auto;
  }

  .top-info.flex-div li:first-child a {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }

  .top-info li a,
  .dropbtn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    background: transparent;
  }

  .top-info li {
    display: inline-flex;
  }

  .top-info>li {
    padding: 0 0 0 2em !important;
  }

  .top-info.flex-div li:nth-child(2) {
    order: 2;
  }

  .top-info.flex-div li.mobile-links {
    order: 1;
  }

  .top-info.flex-div li:nth-child(4) {
    order: 3;
  }
}

@media (max-width: 480px) {
  .top-info li:not(:first-child) {
    padding: 0 5px;
    display: inline-flex !important;
    text-align: center;
    justify-content: center;
  }

  .tophd_left {
    width: 180px;
  }
}

@media (max-width: 380px) {
  .tophd_left {
    width: 180px;
  }

  .top_logo img {
    max-width: 100%;
  }
}

/* HEADER CSS */
header {
  width: 100%;
  clear: both;
  background: #fff;
  position: relative;
  z-index: 1000;

  .flex-div {
    flex-wrap: nowrap;
  }

  height: 80px;
}

.menuoverlay:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
}

.dropdown-menu li a {
  display: block;
  width: 100%;
  border-radius: 0;
  color: $mine-shaft;
  text-align: left;
}

/* .dropdown-menu li:hover>a {
  color: #ffffff;
} */

.dropdown-menu li {
  padding: 0;
  color: $mine-shaft;
  text-align: left;
}

.top-header-right {
  position: relative;
  padding: 0 30px;
  color: $mine-shaft;
  text-align: left;
}

.notify-icon {
  width: 60px;
  display: inline-block;
  vertical-align: top;
  color: $curriki-main-blue;
}

.notify-description {
  width: calc(100% - 65px);
  display: inline-block;
}

.nav-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0;
  color: $curriki-main-blue;
  opacity: 1;
}

.navmenu li {
  padding: 30px 35px;
  border-bottom: 1px solid #eee !important;
}

.navmenu li:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.notify-description p {
  padding-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $mine-shaft;
  opacity: 0.8;
  white-space: initial;
}

.notify-icon i {
  font-size: 32px;
  color: #607a9b;
}

header.head_mobile {
  display: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

//toasitify styles
.project-loading {
  background: $fun-blue-1 !important;
  color: #fff !important;
  box-shadow: 0 1px 10px 0 #000000 !important;

  .Toastify__progress-bar {
    background: #fff !important;
  }
}

.expand-hamberg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  // position: absolute;
  width: 40px;
  height: 40px;
  left: 12px;
  top: 12px;

  background: #e5eeff;
  border-radius: 20px;
}

.help-redirect-icon .swal2-confirm {
  position: relative;
  padding-left: 30px !important;

  &::before {
    content: "";

    background: url("../../assets/images/external-link.svg") no-repeat;
    width: 20px;
    height: 20px;
    padding: 12px;

    position: absolute;
    left: 45px;
    top: 4px;
  }
}